import ApiEndpoint from "./ApiEndpoint";
import axios from "axios";
import {throwErrorToast} from "../components/admin/aaras/NewNomination";
import {
    setFetchStatus,
    setRIDData,
    setUpdateAwardsDataStatus,
    setUpdateDataStatus, setUpdateReportDeadlinesDataStatus
} from "../components/redux/slice/ridistrictSlice";

export function getHomeDistrictData(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setFetchStatus('pending'));
    dispatch(setRIDData(null));

    axios(apiEndPoint.getHomeDistrictData(user)).then(res => {
        if (res.status === 200) {
            dispatch(setRIDData(res.data));
            dispatch(setFetchStatus('completed'));
        }
        return res;
    }).catch(e => {
        dispatch(setFetchStatus('completed'));
        throwErrorToast(e);
    });
}

export function updateHomeDistrictData(dispatch, user, updateRiDistrictRequest) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setUpdateDataStatus('pending'));
    dispatch(setRIDData(null));

    axios(apiEndPoint.updateHomeDistrictData(user, updateRiDistrictRequest)).then(res => {
        if (res.status === 204) {
            dispatch(setRIDData(res.data));
            dispatch(setUpdateDataStatus('pending'));
        }
        return res;
    }).catch(e => {
        dispatch(setUpdateDataStatus('completed'));
        throwErrorToast(e);
    });
}

export function updateHomeDistrictAwardsData(dispatch, user, awardsDetailsList) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setUpdateAwardsDataStatus('pending'));
    dispatch(setRIDData(null));

    axios(apiEndPoint.updateHomeDistrictAwardsData(user, awardsDetailsList)).then(res => {
        if (res.status === 202) {
            dispatch(setRIDData(res.data));
            dispatch(setUpdateAwardsDataStatus('pending'));
        }
        return res;
    }).catch(e => {
        dispatch(setUpdateAwardsDataStatus('completed'));
        throwErrorToast(e);
    });
}

export function updateHomeDistrictDeadlinesData(dispatch, user, reportDeadlines) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setUpdateReportDeadlinesDataStatus('pending'));

    console.log(Array.isArray(reportDeadlines));

    console.log(apiEndPoint.updateHomeDistrictDeadlinesData(user, reportDeadlines));

    return axios(apiEndPoint.updateHomeDistrictDeadlinesData(user, reportDeadlines)).then(res => {
        if (res.status === 204) {
            getHomeDistrictData(dispatch, user)
            dispatch(setUpdateReportDeadlinesDataStatus('completed'));
        }
        console.log(res);
        return res;
    }).catch(e => {
        dispatch(setUpdateReportDeadlinesDataStatus('completed'));
        console.log(e);
        throwErrorToast(e);
    });
}