import ApiEndpoint from "./ApiEndpoint";
import {setAaras, setStatus} from "../components/redux/slice/aaraSlice";
import axios from "axios";
import {toast} from "react-toastify";
import {toastOptions} from "../constants/WebPageConstants";
import React from "react";

export function getAaraNominations(dispatch, user) {

    const apiEndPoint = new ApiEndpoint();
    dispatch(setStatus("pending"));

    return axios(apiEndPoint.getAaraNominations(user))
        .then((res) => {
            if (res.status === 200) {
                dispatch(setAaras(res.data));
                dispatch(setStatus("complete"));
            }
            return res;
        })
        .catch((e) => {
            dispatch(setStatus("complete"));
            toast.error(<>
                <p className="text-medium">{null != e?.response?.data?.code ? e.response.data.code.replaceAll("_", "") : "Internal Server Error"}</p>
                <p className="text-small">{null != e?.response?.data?.message ? e.response.data.message : "Please contact IT Team to report and resolve the error"}</p>
            </>, toastOptions);
        });
}